<script setup lang="ts">
import Button from '../design-system/button.vue';
import FileUpload from 'primevue/fileupload';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { useAuthStore } from '~/stores/auth.store';
import { toastPassThrough } from '~/components/passthroughs/toast';
import { fileUploadPassthrough } from '~/components/passthroughs/fileupload';
import {
  ArrowUpOnSquareStackIcon,
  DocumentIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';

interface IDocumentUpload {
  documentName: string;
  documentExtension: string;
  documentType: string;
  documentStatus: string;
  accountId: string;
  data: string | any;
}

interface IFileUploadProps {
  documentType: string | number;
}

const { $t } = useNuxtApp();

const props = defineProps<IFileUploadProps>();

const { $complianceService } = useServices();
const auth = useAuthStore();

const emits = defineEmits(['uploadFile', 'deleteFile', 'uploading']);

const toast = useToast();

const totalSize = ref(0);
const totalSizePercent = ref(0);
const files = ref([]);
const docPreview = ref();

const onRemoveTemplatingFile = (file, removeFileCallback, index) => {
  removeFileCallback(index);
  totalSize.value -= parseInt(formatSize(file.size));
  totalSizePercent.value = totalSize.value / 10;
};

const onSelectedFiles = (event) => {
  files.value = event.files;
  files.value.forEach((file) => {
    totalSize.value += parseInt(formatSize(file.size));
    const fileURL = URL.createObjectURL(file);
    docPreview.value = fileURL;
  });
};

const uploadEvent = (callback) => {
  totalSizePercent.value = totalSize.value / 10;
  callback();
};

const formatSize = (bytes) => {
  const k = 1024;
  const dm = 1;
  // const sizes = this.$primevue.config.locale.fileSizeTypes;
  const sizes = ['b', 'kb', 'mb'];

  if (bytes === 0) {
    return `0 ${sizes[0]}`;
  }

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return `${formattedSize} ${sizes[i]}`;
};

function splitFileByExt(fileName: string) {
  const regexArr = fileName?.match(/^(.*?)(?:\.(\w+))?$/);
  const [full, name, ext] = regexArr;
  return { fileName: name, fileExt: ext };
}

async function docToByteArr(uploadedFile: any) {
  const file = uploadedFile as File;
  //returning promise to async await reader.onload so files only get sent once data is converted
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async function (f) {
      resolve(f.target.result.split(',')[1]);
    };
    reader.readAsDataURL(file);
  });
}

async function uploadFile(event) {
  emits('uploading', true);
  for (let i = 0; i < files.value.length; i++) {
    const file = files.value[i];
    const { fileName, fileExt } = splitFileByExt(file.name);
    const document: IDocumentUpload = {
      accountId: auth?.userId,
      documentName: fileName,
      documentType: String(props?.documentType),
      documentExtension: fileExt,
      documentStatus: 'AwaitingApproval',
      data: await docToByteArr(file),
    };

    await $complianceService.uploadDocument(document).then((data) => {
      //if successful
      if (data?.isSuccessful) {
        //call toast
        toast.add({
          severity: 'success',
          summary: $t('success'),
          detail: `${$t('jpc-doc-ver-file')} ${file.name} ${$t(
            'jpc-doc-ver-uploaded'
          )}`,
          life: 3000,
          group: 'toast',
        });

        //emit file object to parent
        emits('uploadFile', data.data, file.objectURL);
      } else {
        //error message
        toast.add({
          severity: 'error',
          summary: $t('error'),
          detail: $t('unable-to-upload-file'),
          life: 3000,
          group: 'toast',
        });
      }
    });
  }
  emits('uploading', false);
}
</script>

<template>
  <div class="card w-full">
    <div class="flex justify-center w-full mx-4">
      <Toast position="bottom-center" group="toast" :pt="toastPassThrough" />
    </div>
    <FileUpload
      custom-upload
      :multiple="true"
      accept="image/*,application/pdf,.doc,.docx,.odt,.fodt,.ott"
      :max-file-size="6000000"
      @uploader="uploadFile($event)"
      @select="onSelectedFiles($event)"
      :pt="fileUploadPassthrough"
    >
      <template
        #header="{ chooseCallback, uploadCallback, clearCallback, files }"
      >
        <hr class="w-full border-dark-900" />
        <div class="flex flex-col sm:flex-row justify-between flex-1 gap-2 m-4">
          <Button
            type="tertiary"
            @click="chooseCallback()"
            class="h-min px-4 justify-center"
            padding="md"
          >
            <PlusIcon class="w-6 mr-1" />
            {{ $t('choose-file') }}
          </Button>
          <div class="flex flex-col gap-2">
            <Button
              :type="files.length > 0 ? 'primary' : 'tertiary'"
              :disabled="!files || files.length === 0"
              @click="uploadEvent(uploadCallback)"
              class="capitalize px-4 justify-center"
            >
              <ArrowUpOnSquareStackIcon class="w-6 mr-1" />
              {{ $t('upload') }}
            </Button>
            <Button
              type="tertiary"
              :disabled="!files || files.length === 0"
              @click="clearCallback()"
              class="px-4 justify-center"
            >
              <XMarkIcon class="w-6 mr-1" />
              {{ $t('cancel') }}
            </Button>
          </div>
        </div>
      </template>
      <template #empty>
        <div class="hidden sm:flex items-center justify-center flex-col">
          <DesignSystemIconsDragFileUploadIcon class="" />
          {{ $t('drag-and-drop-files') }}
        </div>
      </template>
      <template #content="{ files, removeFileCallback }">
        <div v-if="files.length > 0" class="w-full">
          <div class="flex flex-wrap p-0 gap-1 px-2">
            <div
              v-for="(file, index) of files"
              :key="file.name + file.type + file.size"
              class="card m-0 w-full flex flex-col items-center gap-1"
            >
              <div
                class="flex upload-container py-1 bg-base rounded-lg w-full mr-1/3"
              >
                <div class="m-2 flex justify-center w-6">
                  <img
                    v-if="file?.objectURL"
                    role="presentation"
                    :alt="file.name"
                    :src="file?.objectURL"
                    width="24"
                    height="24"
                    class="shadow-2"
                  />
                  <DocumentIcon v-else class="w-4" />
                </div>
                <div class="flex flex-col justify-center w-4/5">
                  <span class="font-semibold text-xs">
                    {{ file.name }} ({{ formatSize(file.size) }})
                  </span>
                  <span class="text-xs font-normal capitalize">
                    {{ $t('requested') }}:
                    {{
                      new Date()
                        .toLocaleDateString('en-GB')
                        .replaceAll('/', '-')
                    }}
                  </span>
                </div>
                <TrashIcon
                  class="w-4 cursor-pointer text-dark-700 dark:text-white ml-auto content-center mr-2"
                  @click="
                    onRemoveTemplatingFile(file, removeFileCallback, index)
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </FileUpload>
  </div>
</template>

<style lang="scss">
.p-fileupload {
  &-buttonbar,
  &-content {
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: none;
  }
  &-file {
    max-width: 150px;
    max-height: 150px;
  }
}

.p-fileupload-file {
  &-details,
  &-thumbnail {
    color: #464f60;
    .dark & {
      color: #fff;
    }
  }
}

.p-fileupload-content {
  padding: 0;
}

div.p-fileupload-content div.upload-container {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  width: 100%;
  padding-left: 8px !important;
  padding-right: 16px !important;
  font-size: 12px;
  border: 1px solid #dae0ed;
  border-radius: 6px;
  color: #2b303b;
  .dark & {
    color: #fff;
    border-color: #464f60;
    background-color: #1d2129;
  }
}

span.p-button.p-component.p-fileupload-choose {
  border: 1px solid #dae0ed;
  background-color: #fff;
  .dark & {
    background-color: #1d2129;
    border-color: #464f60;
  }
}
</style>

<style scoped lang="scss">
.dot {
  height: 8px;
  width: 8px;
  border-radius: 100%;
}
.status-div {
  cursor: default;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: right;
  align-items: center;
  border-radius: 4px;
  height: fit-content;
}
</style>
