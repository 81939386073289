<script setup lang="ts">
import { useAuthStore } from '~/stores/auth.store';
import type { IUploadedDocs } from '~/interfaces/dto/compliance/documentVerificationInterfaces';
import { accordionTabPassthrough } from '~/components/passthroughs/accordion';

import AccordionDocVerification from '../design-system/accordion-doc-verification.vue';

const { $t } = useNuxtApp();
const { $complianceService, $authService } = useServices();
const auth = useAuthStore();
const idDocs: IUploadedDocs[] = reactive([]);
const bankDocs: IUploadedDocs[] = reactive([]);
const supportingDocs: IUploadedDocs[] = reactive([]);
const idStatus: globalThis.Ref<number> = ref();
const bankStatus: globalThis.Ref<number> = ref();
const supportingStatus: globalThis.Ref<number> = ref();
const ficaApproved: globalThis.Ref<boolean> = ref(false);
const loader = ref(true);
import Accordion from 'primevue/accordion';

// function isImage(file: any): boolean {
//   const fileExtenstion = file.name.split('.').pop();
//   return ['jpg', 'jfif', 'jpeg', 'png', 'gif'].includes(fileExtention);
// }

function uploadFile(document: IUploadedDocs, thumbnail?) {
  //display newly uploading pending files
  const doc = document;
  const docType = doc.documentType;

  doc.thumbnail = thumbnail;
  if (docType === 0 || docType === 3) {
    idDocs.push(doc);
  } else {
    if (docType === 2) {
      bankDocs.push(doc);
    } else {
      supportingDocs.push(doc);
    }
  }
}

function deleteFile(file: IUploadedDocs) {
  const docType = file.documentType;
  if (docType === 0 || docType === 3) {
    //proofOfID
    for (let i = 0; i < idDocs.length; i++) {
      if (idDocs[i].documentId === file.documentId) {
        idDocs.splice(i, 1);
      }
    }
  } else {
    if (docType === 2) {
      //bank docs
      for (let i = 0; i < bankDocs.length; i++) {
        if (bankDocs[i].documentId === file.documentId) {
          bankDocs.splice(i, 1);
        }
      }
    } else {
      //supporting docs
      for (let i = 0; i < supportingDocs.length; i++) {
        if (supportingDocs[i].documentId === file.documentId) {
          supportingDocs.splice(i, 1);
        }
      }
    }
  }
}

async function getDocs() {
  await $complianceService.getDocuments().then((data) => {
    //if data not successful display error TODO
    if (data?.isSuccessful) {
      const docs = data.data;
      //refer to notes for the rest of this algo
      docs.forEach((doc) => {
        if (doc.documentType === 0 || doc.documentType === 3) {
          //proofOfID
          idDocs.push(doc);
        } else {
          if (doc.documentType === 2) {
            bankDocs.push(doc);
          } else {
            //supporting docs
            supportingDocs.push(doc);
          }
        }
      });
    } else {
      //display error
    }
  });
}

function initDocVer() {
  idStatus.value = auth.getIdDocumentStatus;
  bankStatus.value = auth.getBankDocumentStatus;
  supportingStatus.value = auth.getSupportingDocumentStatus;
  ficaApproved.value = auth.getComplianceStatus > 0;
}

onBeforeMount(async () => {
  await $authService.getUserExtended().then(() => {
    loader.value = false;
  }); //calling this so that compliance values are updated if navigated immediately after post reg
  initDocVer();
  await getDocs();
});
</script>

<template>
  <div class="relative">
    <LazyUserInterfaceGenericLoader v-if="loader" container />
    <div
      class="mx-auto px-2 dark:text-white lg:w-190"
      :class="loader ? 'blur-4' : 'blur-0'"
    >
      <div class="doc-ver-gradient rounded-lg text-sm">
        <p class="ml-2 pt-3 mb-0 text-base-priority text-base-size font-bold">
          {{ $t('document-verification') }}
        </p>
        <div class="p-2 mb-2" v-html="$t('doc-ver-explainer')" />
      </div>
      <Accordion
        :value="!ficaApproved ? '0' : ''"
        :multiple="false"
        :pt="accordionTabPassthrough.accordion"
      >
        <AccordionDocVerification
          :header="'identity-verification'"
          :header-status="idStatus"
          :documents="idDocs"
          value="0"
          document-type="ProofOfIdDocument"
          @delete-file="deleteFile"
          @upload-file="uploadFile"
        >
          <template #accordionContent>
            <div v-html="$t('doc-ver-id-verification')"></div>
          </template>
        </AccordionDocVerification>
        <AccordionDocVerification
          v-if="ficaApproved"
          :header="'bank-verification'"
          :header-status="bankStatus"
          :documents="bankDocs"
          value="1"
          document-type="ProofOfBankDocument"
          @delete-file="deleteFile"
          @upload-file="uploadFile"
        >
          <template #accordionContent>
            <div v-html="$t('doc-ver-bank-verification')"></div>
          </template>
        </AccordionDocVerification>
        <AccordionDocVerification
          v-if="ficaApproved"
          :header="'supporting-documentation'"
          :header-status="supportingStatus"
          :documents="supportingDocs"
          value="2"
          document-type="ProofOfResidenceDocument"
          @delete-file="deleteFile"
          @upload-file="uploadFile"
        >
          <template #accordionContent>
            <div class="my-2">
              <p class="pb-2 my-0">
                {{ $t('doc-ver-supporting-docs') }}
              </p>
            </div>
          </template>
        </AccordionDocVerification>
      </Accordion>
    </div>
  </div>
</template>
