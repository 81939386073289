<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <circle cx="18" cy="18" r="17" stroke="currentColor" stroke-width="2" />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="-10 -9 32 32"
      fill="currentColor"
    >
      <path
        d="M16.5003 14.6654H19.8462C20.1059 14.6654 20.3236 14.7532 20.4993 14.9289C20.675 15.1046 20.7628 15.3223 20.7628 15.582C20.7628 15.8418 20.675 16.0595 20.4993 16.2352C20.3236 16.4108 20.1059 16.4987 19.8462 16.4987H18.7003L20.7628 18.5612C20.9309 18.7293 21.0149 18.9393 21.0149 19.1914C21.0149 19.4435 20.9309 19.6612 20.7628 19.8445C20.5795 20.0279 20.3618 20.1195 20.1097 20.1195C19.8576 20.1195 19.6399 20.0279 19.4566 19.8445L17.417 17.8049V18.9279C17.417 19.1876 17.3291 19.4053 17.1535 19.581C16.9778 19.7567 16.76 19.8445 16.5003 19.8445C16.2406 19.8445 16.0229 19.7567 15.8472 19.581C15.6715 19.4053 15.5837 19.1876 15.5837 18.9279V15.582C15.5837 15.3223 15.6715 15.1046 15.8472 14.9289C16.0229 14.7532 16.2406 14.6654 16.5003 14.6654ZM11.917 3.66536V7.33203C11.917 7.59175 12.0048 7.80946 12.1805 7.98516C12.3562 8.16085 12.5739 8.2487 12.8337 8.2487H16.5003L11.917 3.66536ZM5.50033 1.83203H12.0774C12.3219 1.83203 12.5548 1.87786 12.7764 1.96953C12.9979 2.0612 13.1927 2.19106 13.3607 2.35911L17.8066 6.80495C17.9746 6.973 18.1045 7.16779 18.1962 7.38932C18.2878 7.61085 18.3337 7.84384 18.3337 8.08828V11.9154C18.3337 12.1751 18.2458 12.3928 18.0701 12.5685C17.8944 12.7442 17.6767 12.832 17.417 12.832H14.667C14.4073 12.832 14.1896 12.9199 14.0139 13.0956C13.8382 13.2713 13.7503 13.489 13.7503 13.7487V19.2487C13.7503 19.5084 13.6625 19.7261 13.4868 19.9018C13.3111 20.0775 13.0934 20.1654 12.8337 20.1654H5.50033C4.99616 20.1654 4.56456 19.9858 4.20553 19.6268C3.84651 19.2678 3.66699 18.8362 3.66699 18.332V3.66536C3.66699 3.1612 3.84651 2.7296 4.20553 2.37057C4.56456 2.01155 4.99616 1.83203 5.50033 1.83203Z"
        fill="currentColor"
      />
    </svg>
  </svg>
</template>
